import React from 'react';
import styled from 'styled-components';
import TemplatePage1 from '@components/TemplatePage/TemplatePage';
import pg from "@images/estructura/core/pg.png";
import ez from "@images/estructura/core/ez.png";
import laura from "@images/estructura/core/laura.png";
import cc from "@images/estructura/core/cc.png";
import pm from "@images/estructura/core/pm.png";
import agustina from "@images/estructura/core/agustina.png";
import jm from "@images/estructura/core/jm.png";
import rp from "@images/estructura/core/rp.png";
import ab from "@images/estructura/core/ab.png";
import mes from "@images/estructura/core/mes.png";
import jr from "@images/estructura/core/jr.png";



const IndexPage = () => {


    return (
        <TemplatePage1
            title={"Centros Core"}
            showtitle={false}
            description={"Centros Core"}
            bg={"var(--tema4)"}
            hero={true}
            content={(
                <Wrapper>
                    <MiniWrapper>
                        <Card type={2}>
                            <img src={pg} alt={pg} />

                            <div>
                                <h1>PATRICIA J. GARCÍA </h1>
                                <h2>Quest LAC Network Co-Director</h2>
                                <h3>Principal Proffesor of Public Health and  Administration Faculty</h3>
                            </div>
                        </Card>
                        <Card type={2}>
                            <img src={ez} alt={ez} />

                            <div>
                                <h1>EZEQUIEL GARCÍA - ELORRIO</h1>
                                <h2>Quest LAC Network Co-Director</h2>
                                <h3>Institute of Clinical and Sanitarian Effectiveness Director </h3>
                            </div>
                        </Card>
                    </MiniWrapper>
                    <MiniWrapper>
                        <MicroWrapper>
                        <Card>
                            <div>
                                <h1>CESAR CÁRCAMO </h1>
                                <h2>Phd. on Epidemiology, Researcher and BioStatitian of Quest LAC Network </h2>


                            </div>
                            <img src={cc} alt={cc} />

                        </Card>
                        <Card left>
                            <div>
                                <h1>PATRICIA MALLMA </h1>
                                <h2>Phd. on Public Health and Statistian of the Quest LAC Network</h2>


                            </div>
                            <img src={pm} alt={pm} />

                        </Card>
                        <Card>
                            <div>
                                <h1>RENZO CALDERÓN</h1>
                                <h2>MD , MSc, PhD on Epidemiology</h2>


                            </div>
                            <img src={rp} alt={rp} />

                        </Card>
                        <Card left>
                            <div>
                                <h1>JESÚS MEDINA</h1>
                                <h2>MD Researcher of the Quest LAC Network</h2>


                            </div>
                            <img src={jm} alt={jm} />

                        </Card>
                        <Card>
                            <div>
                                <h1>LAURA ESPINOZA </h1>
                                <h2>Researcher Assistant of the Quest LAC Network</h2>


                            </div>
                            <img src={laura} alt={laura} />

                        </Card>
                        <Card left>
                            <div>
                                <h1>ANTHONY BACILIO</h1>
                                <h2>Web master - QuEST LAC</h2>
                                


                            </div>
                            <img src={ab} alt={ab} />

                        </Card>
                        </MicroWrapper>
                        <MicroWrapper>
                        <Card>
                            <div>
                                <h1>AGUSTINA MAZZONI</h1>
                                <h2>Researcher of Institute of Clinical and Sanitarian Effectiveness</h2>

                            </div>
                            <img src={agustina} alt={agustina} />

                        </Card>
                        <Card left>
                            <div>
                                <h1>MARÍA EUGENIA SERRES</h1>
                                <h2>QuEST LAC Administration Assistant</h2>
                            
                            </div>
                            <img src={mes} alt={mes} />

                        </Card>
                        <Card >
                            <div>
                                <h1>JAVIER ROBERTI</h1>
                                <h2>Researcher of Quest LAC Network and Institute of Clinical and Sanitarian Effectiveness</h2>
                            
                            </div>
                            <img src={jr} alt={jr} />
                        </Card>
                        </MicroWrapper>
                    </MiniWrapper>
                    
                </Wrapper>
            )}
        />
    )
};

export default IndexPage

const Wrapper = styled.div`
color: white;
width: 100%;
max-width:100%;
`;

const MicroWrapper = styled.div`
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
margin: 20px;
width:100%;
h1{
    margin:2px 5px;
    font-size: 1.5rem;
    font-weight: bold;
}
h1{
    margin:2px 5px;
    font-size: 1.3rem;
    
}
h1{
    margin:2px 5px;
    font-size: 1.1rem;
}

img{
    margin:5px;
    width:${props => props.type === 2 ? "60%" : "40%"};
    max-width: ${props => props.type === 2 ? "300px" : "200px"};
}

@media only screen and (min-width:1024px){
    width:45%;
}


`

const MiniWrapper = styled.div`
width: 100%;
padding:15px;
display: flex;
flex-direction:column;
align-items: center;
justify-content: center;
@media only screen and (min-width:1024px){
    display: flex;
flex-direction:row;
align-items: stretch;
justify-content: center;
}
`;

const Card = styled.div`
margin: 5px;
display: flex;
flex-direction: ${props=>props.left? "row-reverse":"row"};
align-items: ${props => props.type === 2 ? "flex-end" : "center"};
justify-content: ${props => props.type === 2 || !props.left ? "flex-start" : "flex-end"};
width:100%;
text-align: ${props => props.type === 2|| props.left ? "left" : "right"};

h1{
    margin:2px 5px;
    font-size: 1.5rem;
    font-weight: bold;
}
h1{
    margin:2px 5px;
    font-size: 1.3rem;
    
}
h1{
    margin:2px 5px;
    font-size: 1.1rem;
}

img{
    margin:5px;
    width:${props => props.type === 2 ? "60%" : "40%"};
    max-width: ${props => props.type === 2 ? "300px" : "200px"};
}


`;